/** @jsx jsx */
import * as React from "react"
import { jsx, Container, Heading, Text } from "theme-ui"
import { TheLayout } from "../components/TheLayout"

const ContactPage = () => {
  return (
    <>
    <TheLayout>
    <Container sx={{my: [3, 4]}}>

      <title>Contact Page</title>

    <Heading variant="display" sx={{ fontWeight: 600,fontSize: [4,5,6]}}>Contact.</Heading>

    <Text sx={{variant: `text.bodytext`}}><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec maximus nulla eget nulla rhoncus, nec fringilla enim sollicitudin. Duis accumsan velit id nisi lacinia consequat. Morbi pretium tellus eu elementum iaculis. Vivamus et interdum augue, eu tempor leo. Proin varius orci vitae tincidunt vestibulum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Praesent mauris arcu, mollis in condimentum id, cursus a sem. Quisque eu erat massa. Nam mauris diam, ullamcorper nec leo ornare, consectetur aliquam arcu. Ut dapibus neque nec aliquet maximus.</p>

        </Text>
    </Container>
    </TheLayout>
    </>
  )
}

export default ContactPage
